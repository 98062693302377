<template>
	<Dialog
		v-if="milestoneDetailDialog"
		:dialog="milestoneDetailDialog"
		title="Project Detail"
		current
		:dialogWidth="1000"
		v-on:close="$emit('close')"
	>
		<template v-slot:title>
			<div class="d-flex justify-space-between w-100">
				<div>
					{{ detailDialogData?.phase }}
				</div>
				<div>
					<v-tooltip top content-class="custom-top-tooltip">
						<template v-slot:activator="{ on, attrs }">
							<v-chip
								:color="getStatusColor(detailDialogData.status_value)"
								label
								v-bind="attrs"
								v-on="on"
								outlined
							>
								{{ detailDialogData.status_value }}
							</v-chip>
						</template>
						<span> Status </span>
					</v-tooltip>
				</div>
			</div>
		</template>
		<template v-slot:body>
			<div class="d-flex justify-space-between">
				<div style="width: 45%">
					<div class="d-flex">
						<div class="fw-500 mr-1 mb-1" style="width: 150px">Date:</div>
						<div class="fw-400" v-if="detailDialogData?.date">
							{{ formatDate(detailDialogData?.date) }}
						</div>
						<em v-else class="text-muted">no date</em>
					</div>
					<div class="d-flex">
						<div class="fw-500 mr-1 mb-1" style="width: 150px">Milestone Amount:</div>
						<ShowPrice
							truncate
							:object="detailDialogData"
							object-key="amount"
							label="milestone amount"
						></ShowPrice>
					</div>
					<div class="d-flex">
						<div class="fw-500 mr-1 mb-1" style="width: 150px">Reminder Date:</div>
						<div class="fw-400" v-if="detailDialogData?.reminder_date">
							{{ formatDate(detailDialogData?.reminder_date) }}
						</div>
						<em v-else class="text-muted">no reminder date</em>
					</div>
					<div class="d-flex">
						<div class="fw-500 mr-1 mb-1" style="width: 150px">Description :</div>
						<ShowTextAreaValue
							:object="detailDialogData"
							object-key="description"
							label="task Description"
						>
						</ShowTextAreaValue>
					</div>
					<div class="d-flex">
						<div class="fw-500 mr-1 mb-1" style="width: 150px">Deliverable Items :</div>
						<ShowValue
							:object="detailDialogData"
							object-key="total_deliverable_items"
							label="deliverable items"
						>
						</ShowValue>
					</div>
					<div class="d-flex">
						<div class="fw-500 mr-1 mb-1" style="width: 150px">Delivered Items :</div>
						<ShowValue
							:object="detailDialogData"
							object-key="total_delivered_items"
							label="delivered items"
						>
						</ShowValue>
					</div>
				</div>
				<div style="width: 45%">
					<div style="background-color: rgb(245 244 228); height: 100%" class="p-4">
						<div class="font-level-4-bold mb-2 d-flex justify-space-between" style="color: #0d47a1">
							<span>Project</span>
							<div>
								<Chip
									tooltip
									tooltip-text="Project #"
									small
									:text="detailDialogData?.project?.barcode"
									color="blue darken-4"
								></Chip>
							</div>
						</div>
						<div class="d-flex">
							<div class="fw-500 mr-1 mb-1" style="width: 130px">Name:</div>
							<span v-if="detailDialogData.project.name">{{ detailDialogData?.project.name }}</span>
							<em v-else class="text-muted fw-400">No project name</em>
						</div>
						<div class="d-flex">
							<div class="fw-500 mr-1 mb-1" style="width: 130px">Start Date:</div>
							<div class="fw-400" v-if="detailDialogData?.project?.start_date">
								{{ formatDate(detailDialogData?.project?.start_date) }}
							</div>
							<em v-else class="text-muted">no start date</em>
						</div>
						<div class="d-flex">
							<div class="fw-500 mr-1 mb-1" style="width: 130px">End Date:</div>
							<div class="fw-400" v-if="detailDialogData?.project?.end_date">
								{{ formatDate(detailDialogData?.project?.end_date) }}
							</div>
							<em v-else class="text-muted">no end date</em>
						</div>
						<div class="d-flex">
							<div class="fw-500 mr-1 mb-1" style="width: 130px">Project Value:</div>
							<ShowPrice
								truncate
								:object="detailDialogData"
								object-key="project.project_value"
								label="project value"
							></ShowPrice>
						</div>
						<div class="d-flex">
							<div class="fw-500 mr-1 mb-1" style="width: 130px">Project Budget:</div>
							<ShowPrice
								truncate
								:object="detailDialogData"
								object-key="project.cost"
								label="project budget"
							></ShowPrice>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-slot:action>
			<v-btn depressed tile v-on:click="$emit('close')"> Close </v-btn>
		</template>
	</Dialog>
</template>

<script>
import Dialog from "@/view/components/Dialog";
import ShowPrice from "@/view/components/ShowPrice";
import ShowValue from "@/view/components/ShowValue";
import ShowTextAreaValue from "@/view/components/ShowTextAreaValue";

import Chip from "@/view/components/Chip";
export default {
	name: "Calendar-task",
	title: "Calendar-task",
	components: {
		Dialog,
		ShowPrice,
		ShowTextAreaValue,
		ShowValue,
		Chip,
	},
	props: {
		milestoneDetailDialog: {
			type: Boolean,
			default: false,
		},
		detailDialogData: {
			type: Object,
			default: () => {},
		},
	},
};
</script>
