<template>
	<Dialog
		v-if="taskDetailDialog"
		:dialog="taskDetailDialog"
		title="Project Detail"
		current
		:dialogWidth="1000"
		v-on:close="$emit('close')"
	>
		<template v-slot:title>
			<div class="d-flex justify-space-between w-100">
				<div>
					{{ detailDialogData?.name }}
					<v-tooltip top content-class="custom-top-tooltip">
						<template v-slot:activator="{ on, attrs }">
							<v-chip
								:color="getStatusColor(detailDialogData.priority_value)"
								label
								class="ml-2"
								v-bind="attrs"
								v-on="on"
								outlined
							>
								{{ detailDialogData.priority_value }}
							</v-chip>
						</template>
						<span> Priority Value </span>
					</v-tooltip>
				</div>
				<div>
					<v-tooltip top content-class="custom-top-tooltip">
						<template v-slot:activator="{ on, attrs }">
							<v-chip
								:color="getStatusColor(detailDialogData.status_value)"
								label
								v-bind="attrs"
								v-on="on"
								outlined
							>
								{{ detailDialogData.status_value }}
							</v-chip>
						</template>
						<span> Status </span>
					</v-tooltip>
				</div>
			</div>
		</template>
		<template v-slot:body>
			<div class="d-flex justify-space-between">
				<div style="width: 45%">
					<div class="d-flex">
						<div class="fw-500 mr-1 mb-1" style="width: 150px">Start Date:</div>
						<div class="fw-400" v-if="detailDialogData?.expected_start_date">
							{{ formatDate(detailDialogData?.expected_start_date) }}
						</div>
						<em v-else class="text-muted">no start date</em>
					</div>
					<div class="d-flex">
						<div class="fw-500 mr-1 mb-1" style="width: 150px">End Date:</div>
						<div class="fw-400" v-if="detailDialogData?.expected_end_date">
							{{ formatDate(detailDialogData?.expected_end_date) }}
						</div>
						<em v-else class="text-muted">no end date</em>
					</div>
					<div class="d-flex">
						<div class="fw-500 mr-1 mb-1" style="width: 150px">Reminder Type:</div>
						<ShowValue
							:object="detailDialogData"
							object-key="reminder_type_value"
							label="reminder type"
						></ShowValue>
					</div>
					<div class="d-flex">
						<div class="fw-500 mr-1 mb-1" style="width: 150px">Reminder Start Date:</div>

						<div class="fw-400" v-if="detailDialogData?.reminder_start_date">
							{{ formatDate(detailDialogData?.reminder_start_date) }}
						</div>
						<em v-else class="text-muted">no reminder start date</em>
					</div>
					<div class="d-flex">
						<div class="fw-500 mr-1 mb-1" style="width: 150px">Reminder End Date:</div>
						<div class="fw-400" v-if="detailDialogData?.reminder_start_date">
							{{ formatDate(detailDialogData?.reminder_start_date) }}
						</div>
						<em v-else class="text-muted">no reminder end date</em>
					</div>
					<div class="d-flex">
						<div class="fw-500 mr-1 mb-1" style="width: 150px">Description :</div>
						<div style="max-width: 270px">
							<ShowTextAreaValue
								:object="detailDialogData"
								object-key="description"
								label="task Description"
							>
							</ShowTextAreaValue>
						</div>
					</div>
				</div>
				<div style="width: 45%">
					<div style="background-color: rgb(245 244 228); height: 100%" class="p-4">
						<div class="font-level-4-bold mb-2 d-flex justify-space-between" style="color: #0d47a1">
							<span>Project</span>
							<div>
								<Chip
									tooltip
									tooltip-text="Project #"
									small
									:text="detailDialogData?.project_barcode"
									color="blue darken-4"
								></Chip>
							</div>
						</div>
						<div class="d-flex">
							<div class="fw-500 mr-1 mb-1" style="width: 130px">Name:</div>
							<ShowValue :object="detailDialogData" object-key="project_name" />
						</div>
						<div class="d-flex">
							<div class="fw-500 mr-1 mb-1" style="width: 130px">Start Date:</div>
							<div class="fw-400" v-if="detailDialogData?.project_start_date">
								{{ formatDate(detailDialogData?.project_start_date) }}
							</div>
							<em v-else class="text-muted">no start date</em>
						</div>
						<div class="d-flex">
							<div class="fw-500 mr-1 mb-1" style="width: 130px">End Date:</div>
							<div class="fw-400" v-if="detailDialogData?.project_end_date">
								{{ formatDate(detailDialogData?.project_end_date) }}
							</div>
							<em v-else class="text-muted">no end date</em>
						</div>
						<div class="d-flex">
							<div class="fw-500 mr-1 mb-1" style="width: 130px">Project Value:</div>
							<ShowPrice
								truncate
								:object="detailDialogData"
								object-key="project_value"
								label="project value"
							></ShowPrice>
						</div>
						<div class="d-flex">
							<div class="fw-500 mr-1 mb-1" style="width: 130px">Project Budget:</div>
							<ShowPrice
								truncate
								:object="detailDialogData"
								object-key="project_cost"
								label="project budget"
							></ShowPrice>
						</div>
					</div>
				</div>
				<!-- <div style="width: 45%">
					<div class="d-flex">
						<div class="fw-500 mr-1 mb-1" style="width: 150px">Project Name:</div>
						<ShowValue
							:object="detailDialogData"
							object-key="project_name"
							label="project name"
						></ShowValue>
					</div>
					
				</div> -->
			</div>
		</template>
		<template v-slot:action>
			<v-btn depressed tile v-on:click="$emit('close')"> Close </v-btn>
		</template>
	</Dialog>
</template>

<script>
import Dialog from "@/view/components/Dialog";
import ShowPrice from "@/view/components/ShowPrice";
import Chip from "@/view/components/Chip";
import ShowValue from "@/view/components/ShowValue";
// import Chip from "@/view/components/Chip";
import ShowTextAreaValue from "@/view/components/ShowTextAreaValue";
//  import ReadMore from 'vue-read-more';

export default {
	name: "Calendar-task",
	title: "Calendar-task",
	components: {
		Dialog,
		// ReadMore,
		Chip,
		ShowPrice,
		ShowTextAreaValue,
		ShowValue,
		// Chip,
	},
	props: {
		taskDetailDialog: {
			type: Boolean,
			default: false,
		},
		detailDialogData: {
			type: Object,
			default: () => {},
		},
	},
};
</script>
