<template>
	<div class="calendar" style="height: calc(100vh - 88px)">
		<v-sheet>
			<v-row class="mx-2">
				<v-col xl="2" lg="3" md="3" sm="2">
					<label class="h1-label" style="margin-top: 13px !important">Filters</label>
					<table style="table-layout: fixed" width="100%">
						<tr>
							<td height="15px"></td>
						</tr>
						<tr>
							<td>
								<label for="calendar-dropdown">Filter By Module</label>
								<v-select
									:items.sync="calendarModuleSelectionList1"
									outlined
									hide-details
									v-model="calendarModuleSelection"
									@change="changeSelectedModule"
								>
									<template v-slot:item="{ item }">
										<v-list-item-content>
											<v-list-item-title class="d-flex justify-space-between">
												<div>
													{{ item.text }}
												</div>
												<!-- <span class="font-level-2-bold ml-4">{{ item.count }}</span> -->
												<div>
													<v-chip small text :color="getModuleColor(item.value)" class="mx-2 white--text">{{
														item.count
													}}</v-chip>
													<!-- <v-icon :color="getModuleColor(item.value)"> mdi-circle </v-icon> -->
												</div>
											</v-list-item-title>
										</v-list-item-content>
										<!-- <v-list-item-action> -->
										<!-- <v-avatar size="25" text-color="white" :color="getModuleColor(item.value)">
												<span class="white--text">		{{ item.count }}</span>
												<v-badge>{{ item.count }}</v-badge>
											</v-avatar> -->
										<!-- <v-icon :color="getModuleColor(item.value)"> mdi-circle </v-icon> -->
										<!-- </v-list-item-action> -->
									</template>
								</v-select>
								<!-- <v-select></v-select> -->
							</td>
						</tr>
						<tr>
							<td height="15px"></td>
						</tr>
						<tr>
							<td>
								<label for="calendar-search">Search</label>
								<TextInput
									hide-top-margin
									hide-details
									id="calendar-search"
									placeholder="Search"
									clearable
									v-model="filter.search"
									v-on:click:clear="clearFilter('search')"
									v-on:change="doFilter()"
									@keyup="searchEnter"
								></TextInput>
							</td>
						</tr>
						<tr>
							<td height="15px"></td>
						</tr>
						<tr>
							<td>
								<label for="calendar-date">Date</label>
								<DatePicker
									id="calendar-date"
									hide-top-margin
									hide-details
									placeholder="Date"
									clearable
									v-model="calendarFilter.date"
									v-on:click:clear="clearCalendarFilter('date')"
									v-on:change="changeCalendar('date')"
								></DatePicker>
							</td>
						</tr>
						<tr>
							<td height="15px"></td>
						</tr>
						<tr>
							<td>
								<label for="calendar-month">Month / Year</label>
								<v-layout>
									<v-flex md7>
										<SelectInput
											placeholder="Month"
											:items="month_lists"
											item-text="label"
											item-value="value"
											hide-details
											hide-top-margin
											id="calendar-month"
											clearable
											v-model="calendarFilter.month"
											v-on:click:clear="clearCalendarFilter('month')"
											v-on:change="changeCalendar('month')"
										></SelectInput>
									</v-flex>
									<v-flex md5>
										<SelectInput
											placeholder="Year"
											:items="year_lists"
											item-text="label"
											item-value="value"
											hide-details
											hide-top-margin
											id="calendar-year"
											clearable
											v-model="calendarFilter.year"
											v-on:click:clear="clearCalendarFilter('year')"
											v-on:change="changeCalendar('year')"
										></SelectInput>
									</v-flex>
								</v-layout>
							</td>
						</tr>
						<tr>
							<td height="15px"></td>
						</tr>
						<tr v-if="false">
							<td>
								<label for="calendar-engineer">Engineer</label>
								<SelectInput
									placeholder="Engineer"
									:items="engineer_lists"
									item-text="text"
									item-value="value"
									hide-details
									hide-top-margin
									id="calendar-engineer"
									clearable
									v-model="filter.engineer"
									v-on:click:clear="clearFilter('engineer')"
									v-on:change="doFilter()"
									multiple
								></SelectInput>
							</td>
						</tr>
						<tr v-if="false">
							<td height="15px"></td>
						</tr>
						<tr v-if="false">
							<td>
								<label id="calendar-subcon">Subcon</label>
								<SelectInput
									placeholder="Subcon"
									:items="subcon_lists"
									item-text="text"
									item-value="value"
									hide-details
									hide-top-margin
									id="calendar-subcon"
									clearable
									v-model="filter.subcon"
									v-on:click:clear="clearFilter('subcon')"
									v-on:change="doFilter()"
									multiple
								></SelectInput>
							</td>
						</tr>
						<tr v-if="false">
							<td height="15px"></td>
						</tr>
						<tr v-if="false">
							<td>
								<label for="calendar-calibration-due-date">Calibration Due Date</label>
								<DatePicker
									id="calendar-calibration-due-date"
									hide-top-margin
									hide-details
									placeholder="Calibration Due Date"
									clearable
									v-model="filter.calibration_date"
									v-on:click:clear="clearFilter('calibration_date')"
									v-on:change="doFilter()"
								></DatePicker>
							</td>
						</tr>
						<tr v-if="false">
							<td height="15px"></td>
						</tr>
						<tr v-if="false">
							<td>
								<label for="calendar-onsite-in-lab">Onsite/In Lab</label>
								<SelectInput
									placeholder="Onsite/In Lab"
									:items="type_lists"
									item-text="label"
									item-value="value"
									hide-details
									hide-top-margin
									id="calendar-onsite-in-lab"
									clearable
									v-model="filter.onsite_inlab"
									v-on:click:clear="clearFilter('onsite_inlab')"
									v-on:change="doFilter()"
									multiple
								></SelectInput>
							</td>
						</tr>
					</table>
				</v-col>
				<v-col xl="10" lg="9" md="9" sm="10">
					<div class="d-flex">
						<v-btn icon class="ma-2" v-on:click="$refs.calendar.prev()">
							<v-icon>mdi-chevron-left</v-icon>
						</v-btn>
						<label v-if="$refs.calendar" class="h1-label mx-2">{{ $refs.calendar.title }}</label>
						<v-btn icon class="ma-2" v-on:click="$refs.calendar.next()">
							<v-icon>mdi-chevron-right</v-icon>
						</v-btn>
						<v-spacer></v-spacer>
						<v-btn
							color="blue darken-4 white--text"
							:outlined="is_today"
							depressed
							tile
							class="my-2"
							v-on:click="jumpToDay({ date: null })"
						>
							Today
						</v-btn>
						<!-- </div>
					<v-calendar
						style="min-height: calc(100vh - 210px)"
						ref="calendar"
						v-model="value"
						:type="type"
						:events="events"
						:event-overlap-mode="mode"
						:event-overlap-threshold="30"
						:event-color="getEventColor"
						v-on:change="false && getEvents"
						v-on:click:more="viewMore($event)"
						v-on:click:date="jumpToDay($event)"
						v-on:click:event="jumpToEvent($event)"
						color="blue darken-4 white--text"
					></v-calendar> -->
					</div>
					<v-calendar
						style="min-height: calc(100vh - 210px)"
						ref="calendar"
						v-model="value"
						:type="type"
						:events="events"
						:event-overlap-mode="mode"
						:event-overlap-threshold="30"
						:event-color="getEventColor"
						v-on:click:more="viewMore($event)"
						v-on:click:event="jumpToEvent($event)"
						color="blue darken-4 white--text"
					></v-calendar>
					<v-menu
						top
						v-model="selectedOpen"
						:close-on-content-click="false"
						:activator="selectedElement"
						class="calendar-pop-up"
						left
					>
						<v-card min-width="300px" max-width="300px" flat>
							<v-card-text>
								<div v-if="event_more_loading" class="text-center">
									<v-progress-circular
										:size="70"
										:width="7"
										color="blue darken-4"
										indeterminate
									></v-progress-circular>
								</div>
								<template v-else>
									<v-chip
										v-for="(row, index) in more_events"
										:key="index"
										:color="getStatusColor(row.status_value)"
										label
										class="mb-1 w-100"
										style="color: white !important"
										v-on:click="jumpToEvent({ event: { uuid: row.uuid } })"
									>
										<span
											class="text-uppercase mr-2"
											style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
										></span>
										{{ row.name }}
									</v-chip>
								</template>
							</v-card-text>
						</v-card>
					</v-menu>
				</v-col>
			</v-row>
		</v-sheet>
		<Dialog
			v-if="projectDetailDialog"
			:dialog="projectDetailDialog"
			title="Project Detail"
			current
			:dialogWidth="1000"
			v-on:close="projectDetailDialog = false"
		>
			<template v-slot:title>
				<div class="d-flex justify-space-between w-100">
					<div>{{ detailDialogData?.name }} #{{ detailDialogData?.barcode }}</div>
					<div>
						<v-tooltip top content-class="custom-top-tooltip">
							<template v-slot:activator="{ on, attrs }">
								<v-chip
									:color="getStatusColor(detailDialogData.status_value)"
									label
									v-bind="attrs"
									v-on="on"
									outlined
								>
									{{ detailDialogData?.status_value }}
								</v-chip>
							</template>
							<span> Status </span>
						</v-tooltip>
					</div>
				</div>
			</template>
			<template v-slot:body>
				<div class="d-flex justify-space-between">
					<div style="width: 45%">
						<div class="d-flex">
							<div class="fw-500 mr-1 mb-1" style="width: 130px">Start Date:</div>
							<div class="fw-400" v-if="detailDialogData?.start_date">
								{{ formatDate(detailDialogData?.start_date) }}
							</div>
							<em v-else class="text-muted">No Start Date</em>
						</div>
						<div class="d-flex">
							<div class="fw-500 mr-1 mb-1" style="width: 130px">End Date:</div>
							<div class="fw-400" v-if="detailDialogData?.end_date">
								{{ formatDate(detailDialogData?.end_date) }}
							</div>
							<em v-else class="text-muted">No Start Date</em>
						</div>
						<div class="d-flex">
							<div class="fw-500 mr-1 mb-1" style="width: 130px">Value:</div>
							<ShowPrice
								:object="detailDialogData"
								object-key="project_value"
								label="Project Value"
							></ShowPrice>
						</div>
						<div class="d-flex">
							<div class="fw-500 mr-1 mb-1" style="width: 130px">Budget:</div>
							<ShowPrice :object="detailDialogData" object-key="cost" label="Project Budget"></ShowPrice>
						</div>
						<div class="d-flex">
							<div class="fw-500 mr-1 mb-1" style="width: 130px">Spend:</div>
							<ShowPrice
								:object="detailDialogData"
								object-key="total_expense_amount"
								label="Project Spend"
							></ShowPrice>
						</div>
						<div class="d-flex">
							<div class="fw-500 mr-1 mb-1" style="width: 130px">Total Deliverable:</div>
							<ShowValue
								:object="detailDialogData"
								object-key="total_deliverable"
								label="Total deliverable"
							></ShowValue>
						</div>
						<div class="d-flex">
							<div class="fw-500 mr-1 mb-1" style="width: 130px">Total Delivered:</div>
							<ShowValue
								:object="detailDialogData"
								object-key="total_delivered"
								label="Total Delivered"
							></ShowValue>
						</div>
					</div>
					<div style="width: 45%" v-if="detailDialogData.customers[0]?.id">
						<div style="background-color: rgb(235 240 240); height: 100%" class="p-4">
							<div class="font-level-4-bold mb-2 d-flex justify-space-between" style="color: #0d47a1">
								Customer
								<div>
									<v-tooltip top content-class="custom-top-tooltip">
										<template v-slot:activator="{ on, attrs }">
											<v-chip
												:color="getStatusColor(detailDialogData.customers[0]?.barcode)"
												label
												small
												v-bind="attrs"
												v-on="on"
												outlined
											>
												{{ detailDialogData.customers[0]?.barcode }}
											</v-chip>
										</template>
										<span> Status </span>
									</v-tooltip>
								</div>
							</div>
							<div class="d-flex">
								<div class="fw-500 mr-1 mb-1" style="width: 130px">Display Name:</div>
								<span v-if="detailDialogData.customers[0]?.display_name">{{
									detailDialogData?.customers[0]?.display_name
								}}</span>
								<em v-else class="text-muted fw-400">No customer display name</em>
							</div>
							<div class="d-flex">
								<div class="fw-500 mr-1 mb-1" style="width: 130px">Email:</div>
								<span v-if="detailDialogData.customers[0]?.email">{{
									detailDialogData?.customers[0]?.email
								}}</span>
								<em v-else class="text-muted fw-400">No customer email</em>
							</div>
							<div class="d-flex">
								<div class="fw-500 mr-1 mb-1" style="width: 130px">Email:</div>
								<span v-if="detailDialogData.customers[0]?.phone">{{
									detailDialogData?.customers[0]?.phone
								}}</span>
								<em v-else class="text-muted fw-400">No customer phone</em>
							</div>
							<div class="d-flex">
								<div class="fw-500 mr-1 mb-1" style="width: 130px">Company:</div>
								<span v-if="detailDialogData.customers[0]?.company_name">{{
									detailDialogData?.customers[0]?.company_name
								}}</span>
								<em v-else class="text-muted fw-400">No company name</em>
							</div>
						</div>
					</div>
				</div>
			</template>
			<template v-slot:action>
				<v-btn depressed tile v-on:click="projectDetailDialog = false"> Close </v-btn>
			</template>
		</Dialog>
		<CalendarTaskDetailDialog
			v-if="taskDetailDialog"
			:detailDialogData="detailDialogData"
			:taskDetailDialog="taskDetailDialog"
			@close="taskDetailDialog = false"
		/>
		<CalendarMilestoneDetailDialog
			v-if="milestoneDetailDialog"
			:detailDialogData="detailDialogData"
			:milestoneDetailDialog="milestoneDetailDialog"
			@close="milestoneDetailDialog = false"
		/>
		<CalendarQuotationDetailDialog
			v-if="quotationPopDialog"
			:detailDialogData="detailDialogData"
			:quotationPopDialog="quotationPopDialog"
			@close="quotationPopDialog = false"
		/>
		<CalendarPurchaseOrderDetailDialog
			v-if="purchaseOderDialog"
			:detailDialogData="detailDialogData"
			:purchaseOderDialog="purchaseOderDialog"
			@close="purchaseOderDialog = false"
		/>
	</div>
</template>
<script>
// import AssetServiceDetail from "@/view/components/Asset-Service-Detail";
import SelectInput from "@/view/components/SelectInput";
import ShowPrice from "@/view/components/ShowPrice";
import ShowValue from "@/view/components/ShowValue";
// import Status from "@/view/components/Status";
import CalendarTaskDetailDialog from "@/view/components/CalendarTaskDetailDialog";
import CalendarMilestoneDetailDialog from "@/view/components/CalendarMilestoneDetailDialog";
import CalendarQuotationDetailDialog from "@/view/components/CalendarQuotationDetailDialog";
import CalendarPurchaseOrderDetailDialog from "@/view/components/CalendarPurchaseOrderDetailDialog";
import TextInput from "@/view/components/TextInput";
import DatePicker from "@/view/components/DatePicker";
import ApiService from "@/core/services/api.service";
import MomentJS from "moment-timezone";
import { mapGetters } from "vuex";
import ObjectPath from "object-path";
import Dialog from "@/view/components/Dialog";
import { getCalendarData } from "@/core/lib/calendar.lib";
import { GetProject, getTasksUuid, getMilestoneByUUID } from "@/core/lib/project.lib";
import { GetQuotation } from "@/core/lib/quotation.lib";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import { GetPurchaseOrder } from "@/core/lib/purchase-order.lib";
MomentJS.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
	name: "calendar",
	title: "Calendar",
	data() {
		return {
			type: "month",
			mode: "stack",
			detailDialogData: null,
			taskDetailDialog: false,
			milestoneDetailDialog: false,
			purchaseOderDialog: false,
			quotationPopDialog: false,
			value: MomentJS().format("YYYY-MM-DD"),
			events: [],
			year_lists: [],
			projectDetailDialog: false,
			engineer_lists: [],
			calendarModuleSelection: "all",
			calendarModuleSelectionList: [],
			calendarModuleSelectionList1: [],
			counting: [],
			subcon_lists: [],
			month_lists: [
				{ label: "January", value: "january" },
				{ label: "February", value: "february" },
				{ label: "March", value: "march" },
				{ label: "April", value: "april" },
				{ label: "May", value: "may" },
				{ label: "June", value: "june" },
				{ label: "July", value: "july" },
				{ label: "August", value: "august" },
				{ label: "September", value: "september" },
				{ label: "October", value: "october" },
				{ label: "November", value: "november" },
				{ label: "December", value: "december" },
			],
			type_lists: [
				{ label: "On Site", value: "on-site" },
				{ label: "In Lab", value: "in-lab" },
			],
			selectedOpen: false,
			selectedElement: null,
			filter: {
				search: null,
				date: null,
				month: null,
				year: null,
				engineer: null,
				subcon: null,
				calibration_date: null,
				onsite_inlab: null,
			},
			calendarFilter: {
				date: null,
				month: null,
				year: null,
			},
			event_more_loading: false,
			event_loading: false,
			detail_dialog: false,
			detail_uuid: null,
			more_events: [],
		};
	},
	components: {
		CalendarTaskDetailDialog,
		CalendarMilestoneDetailDialog,
		CalendarQuotationDetailDialog,
		CalendarPurchaseOrderDetailDialog,
		Dialog,
		TextInput,
		SelectInput,
		DatePicker,
		ShowPrice,
		ShowValue,
		// Status,
	},
	methods: {
		viewMore({ date, nativeEvent }) {
			if (this.event_more_loading) {
				return false;
			}

			const open = () => {
				this.selectedElement = nativeEvent.target;
				requestAnimationFrame(() => requestAnimationFrame(() => (this.selectedOpen = true)));
			};

			if (this.selectedOpen) {
				this.selectedOpen = false;
				requestAnimationFrame(() => requestAnimationFrame(() => open()));
			} else {
				open();
			}

			nativeEvent.stopPropagation();

			this.more_events = [];

			this.event_more_loading = true;
			const selectedModule = this.calendarModuleSelection;
			// let type = null;
			// if (selectedModule === 1) {
			// 	type = "tasks";
			// } else if (selectedModule === 2) {
			// 	type = "projects";
			// } else if (selectedModule === 3) {
			// 	type = "milestones";
			// } else if (selectedModule === 4) {
			// 	type = "quotations";
			// } else if (selectedModule === 5) {
			// 	type = "purchase-orders";
			// }
			ApiService.query(`calender/pop-up/${selectedModule}`, { date: date })
				.then((output) => {
					this.more_events = ObjectPath.get(output, "data", []);
					this.more_events = this.more_events.filter((ele) => {
						if (ele.start) {
							return ele;
						}
					});
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.event_more_loading = false;
				});
		},
		clearCalendarFilter(param) {
			this.calendarFilter[param] = null;
		},
		changeCalendar(type) {
			let calendar_date = [];
			let month = MomentJS(this.calendarFilter.date).format("MMMM");
			let year = MomentJS(this.calendarFilter.date).format("YYYY");
			if (type == "date") {
				this.calendarFilter.month = month.toLowerCase();
				this.calendarFilter.year = year;
				if (this.calendarFilter.date) {
					this.value = this.calendarFilter.date;
					this.$refs.calendar.checkChange();
					return false;
				}
			}
			this.clearCalendarFilter("date");
			calendar_date.push(MomentJS().format("DD"));
			if (this.calendarFilter.month) {
				calendar_date.push(this.calendarFilter.month);
			} else {
				calendar_date.push(MomentJS().format("MMMM"));
			}

			if (this.calendarFilter.year) {
				calendar_date.push(this.calendarFilter.year);
			} else {
				calendar_date.push(MomentJS().format("YYYY"));
			}

			this.value = MomentJS(calendar_date.join("-"), "DD-MMMM-YYYY").format("YYYY-MM-DD");

			this.$refs.calendar.checkChange();
		},
		clearFilter(param) {
			this.filter[param] = null;
			const today = ObjectPath.get(this.$refs, "calendar.today");
			const startOfMonth = MomentJS(today).startOf("month").format("YYYY-MM-DD");
			const endOfMonth = MomentJS(today).endOf("month").format("YYYY-MM-DD");
			this.getEvents({ start: { date: startOfMonth }, end: { date: endOfMonth } });
		},
		searchEnter() {
			setTimeout(() => {
				this.initCalendarData();
			}, 350);
		},
		doFilter() {
			// const today = ObjectPath.get(this.$refs, "calendar.today");
			// const startOfMonth = MomentJS(today).startOf("month").format("YYYY-MM-DD");
			// const endOfMonth = MomentJS(today).endOf("month").format("YYYY-MM-DD");
			// this.getEvents({ start: { date: startOfMonth }, end: { date: endOfMonth } });
			this.initCalendarData();
		},
		jumpToDay({ date }) {
			// this.clearCalendarFilter("date");
			// this.clearCalendarFilter("month");
			// this.clearCalendarFilter("year");
			// this.clearCalendarFilter("search");
			// this.clearFilter("engineer");
			// this.clearFilter("subcon");
			// this.clearFilter("calibration_date");
			// this.clearFilter("onsite_inlab");
			if (!date) {
				this.value = MomentJS().format("YYYY-MM-DD");
				return true;
			}
			this.value = date;
		},
		async jumpToEvent(param) {
			// console.log(param.event);
			const selectedModule = param.event.type;
			if (selectedModule == "tasks") {
				const task = await getTasksUuid(param.event.uuid);
				this.detailDialogData = task[0];
				this.taskDetailDialog = true;
			} else if (selectedModule == "projects") {
				const project = await GetProject(param.event.uuid);
				this.projectDetailDialog = true;
				this.detailDialogData = project[0];
			} else if (selectedModule == "milestones") {
				const milestone = await getMilestoneByUUID(param.event.uuid);
				this.detailDialogData = milestone[0];
				this.milestoneDetailDialog = true;
			} else if (selectedModule == "quotations") {
				const quotation = await GetQuotation(param.event.uuid);
				this.detailDialogData = quotation;
				this.quotationPopDialog = true;
			} else if (selectedModule == "purchase-orders") {
				const purchaseOrder = await GetPurchaseOrder(param.event.uuid);
				this.detailDialogData = purchaseOrder;
				this.purchaseOderDialog = true;
			}
			this.detail_uuid = ObjectPath.get(param, "event.uuid");
			if (this.detail_uuid) {
				this.$nextTick(() => {
					this.detail_dialog = true;
				});
			}
		},
		getEvents({ start, end }) {
			const min = new Date(`${start.date}T00:00:00`);
			const max = new Date(`${end.date}T23:59:59`);

			this.events = [];

			const filter = {
				"calendar-min": min,
				"calendar-max": max,
				"calendar-search": this.filter.search,
				"calendar-date": this.filter.date,
				"calendar-month": this.filter.month,
				"calendar-year": this.filter.year,
				"calendar-engineer": this.filter.engineer,
				"calendar-subcon": this.filter.subcon,
				"calendar-calibration-date": this.filter.calibration_date,
				"calendar-onsite-inlab": this.filter.onsite_inlab,
			};
			this.event_loading = true;
			ApiService.query("dashboard/services", filter)
				.then((output) => {
					this.events = ObjectPath.get(output, "data", []);
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.event_loading = false;
				});
		},
		getEventColor(event) {
			return this.getModuleColor(event.type);
		},
		async changeSelectedModule() {
			await this.initCalendarData();
		},
		async initCalendarData() {
			try {
				const selectedModule = this.calendarModuleSelection;
				const { analysis, counting } = await getCalendarData(selectedModule, {
					search: this.filter.search,
				});
				this.calendarModuleSelectionList = counting;
				this.calendarModuleSelectionList.sort((a, b) => a.order - b.order);
				this.events = [];
				this.counting = counting;
				if (analysis.length) {
					analysis.forEach((ele) => {
						if (ele.start) {
							const startDate = new Date(MomentJS(ele.start) - (MomentJS(ele.start) % 900000));
							this.events.push({
								uuid: ele.uuid || ele.id,
								name: ele.name.charAt(0).toUpperCase() + ele.name.slice(1),
								start: startDate,
								end: startDate,
								color: this.getStatusColor(ele.status_value),
								type: ele.type,
							});
						}
					});
				}
			} catch (error) {
				console.log(error);
			}
		},
	},
	async mounted() {
		let two_year = MomentJS().subtract(1, "year").format("YYYY");
		this.year_lists.push({ label: two_year, value: two_year });
		let three_year = MomentJS().format("YYYY");
		this.year_lists.push({ label: three_year, value: three_year });
		let four_year = MomentJS().add(1, "year").format("YYYY");
		this.year_lists.push({ label: four_year, value: four_year });
		this.engineer_lists = this.localDB("engineers", []);
		this.subcon_lists = this.localDB("subcons", []);
		await this.initCalendarData();
		this.calendarModuleSelectionList1 = this.calendarModuleSelectionList;
		this.$store.dispatch(SET_BREADCRUMB, [{ text: "Calendar", disabled: true, href: "#" }]);
	},
	computed: {
		...mapGetters(["localDB"]),
		is_today() {
			return this.value != MomentJS().format("YYYY-MM-DD");
		},
	},
};
</script>
