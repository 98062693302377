<template>
	<Dialog
		v-if="purchaseOderDialog"
		:dialog="purchaseOderDialog"
		title="Project Detail"
		current
		:dialogWidth="1000"
		v-on:close="$emit('close')"
	>
		<template v-slot:title>
			<div class="d-flex justify-space-between w-100">
				<div>#{{ detailDialogData?.barcode }}</div>
				<div>
					<v-tooltip top content-class="custom-top-tooltip">
						<template v-slot:activator="{ on, attrs }">
							<v-chip
								:color="getStatusColor(detailDialogData.status_value)"
								label
								v-bind="attrs"
								v-on="on"
								outlined
							>
								{{ detailDialogData.status_value }}
							</v-chip>
						</template>
						<span> Status </span>
					</v-tooltip>
				</div>
			</div>
		</template>
		<template v-slot:body>
			<div class="d-flex justify-space-between">
				<div style="width: 33%" class="mt-4">
					<div class="d-flex">
						<div class="fw-500 mr-1 mb-1" style="width: 150px">Delivery Date:</div>
						<span v-if="detailDialogData?.date">{{ formatDate(detailDialogData?.date) }}</span>
						<em v-else class="text-muted fw-400">no delivery date</em>
					</div>
					<div class="d-flex">
						<div class="fw-500 mr-1 mb-1" style="width: 150px">Reference:</div>
						<ShowValue
							:object="detailDialogData"
							object-key="reference_number"
							label="reference"
						></ShowValue>
					</div>
					<div class="d-flex">
						<div class="fw-500 mr-1 mb-1" style="width: 150px">Amount:</div>
						<ShowPrice
							truncate
							:object="detailDialogData"
							object-key="total"
							label="quotation total"
						></ShowPrice>
					</div>
				</div>
				<div style="width: 33%">
					<div style="background-color: #f0edd4; height: 100%; border-radius: 0.5rem" class="p-4">
						<div class="font-level-4-bold mb-2 d-flex justify-space-between" style="color: #0d47a1">
							<span>Supplier</span>
							<div>
								<Chip
									tooltip
									tooltip-text="Supplier #"
									small
									:text="detailDialogData?.supplier?.barcode"
									color="blue darken-4"
								></Chip>
							</div>
						</div>
						<div class="d-flex">
							<div class="fw-500 mb-1" style="width: 100px">Name:</div>
							<ShowValue
								:object="detailDialogData.supplier"
								object-key="display_name"
								label="name"
							></ShowValue>
						</div>
						<div class="d-flex">
							<div class="fw-500 mb-1" style="width: 100px">Email:</div>
							<ShowValue :object="detailDialogData.supplier" object-key="email" label="email"></ShowValue>
						</div>
						<div class="d-flex">
							<div class="fw-500 mb-1" style="width: 100px">Phone:</div>
							<ShowValue :object="detailDialogData.supplier" object-key="phone" label="phone"></ShowValue>
						</div>
						<div class="d-flex">
							<div class="d-flex">
								<div class="fw-500 mr-1 mb-1" style="width: 100px">Address:</div>
								<ShowValue
									:object="detailDialogData.supplier"
									object-key="address"
									label="address"
								></ShowValue>
							</div>
						</div>
					</div>
				</div>
				<div v-if="detailDialogData?.customer?.uuid" style="width: 33%">
					<div
						style="background-color: rgb(235 240 240); height: 100%; border-radius: 0.5rem"
						class="p-4"
					>
						<div class="font-level-4-bold mb-2 d-flex justify-space-between" style="color: #0d47a1">
							<span>Customer</span>
							<div>
								<Chip
									tooltip
									tooltip-text="Customer #"
									small
									:text="detailDialogData?.customer?.barcode"
									color="blue darken-4"
								></Chip>
							</div>
						</div>
						<div class="d-flex">
							<div class="fw-500 mr-1 mb-1" style="min-width: 100px">Name:</div>
							<ShowValue
								:object="detailDialogData.customer"
								object-key="display_name"
								label="name"
							></ShowValue>
						</div>
						<div class="d-flex">
							<div class="fw-500 mr-1 mb-1" style="min-width: 100px">Email:</div>
							<ShowValue
								:object="detailDialogData.customer"
								object-key="email"
								label="display name"
							></ShowValue>
						</div>
						<div class="d-flex">
							<div class="fw-500 mr-1 mb-1" style="min-width: 100px">Phone:</div>
							<ShowValue :object="detailDialogData.customer" object-key="phone" label="phone"></ShowValue>
						</div>
						<div class="d-flex">
							<div class="fw-500 mr-1 mb-1" style="min-width: 100px">Address:</div>
							<div class="d-flex flex-column">
								<div>
									{{ detailDialogData?.customer?.address_line_1 }}
								</div>
								<div>
									{{ detailDialogData?.customer?.address_line_2 }}
								</div>
								<div>
									{{ detailDialogData?.customer?.address_country }}
									{{ detailDialogData?.customer?.address_postal_code }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-slot:action>
			<v-btn depressed tile v-on:click="$emit('close')"> Close </v-btn>
		</template>
	</Dialog>
</template>

<script>
import Dialog from "@/view/components/Dialog";
import ShowPrice from "@/view/components/ShowPrice";
import ShowValue from "@/view/components/ShowValue";
import Chip from "@/view/components/Chip";

export default {
	name: "Calendar-task",
	title: "Calendar-task",
	components: {
		Dialog,
		ShowPrice,
		ShowValue,
		Chip,
	},
	props: {
		purchaseOderDialog: {
			type: Boolean,
			default: false,
		},
		detailDialogData: {
			type: Object,
			default: () => {},
		},
	},
};
</script>
